import "./App.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function App() {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Función para extraer el token de la URL y enviarlo al backend
  const getTokenAndSendToBackend = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const encodedToken = urlParams.get("token");
      if (!encodedToken) {
        throw new Error("No se encontró el token en la URL");
      }
      const decodedToken = decodeURIComponent(encodedToken);
      const url =
        `${process.env.REACT_APP_VR_WEB_HOOK_URL}/resolvepurchased`;
      const codeParam =
        `${process.env.REACT_APP_VR_WEB_HOOK_TOKEN}`;
      const fullUrl = `${url}?code=${codeParam}`;

      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "x-ms-marketplace-token": decodedToken,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 400) {
          throw new Error("Token inválido o ya expiró");
        } else {
          throw new Error("Error " + response.statusText);
        }
      }

      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTokenAndSendToBackend();
  }, []);

  return (
    <div className="container">
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : error ? (
        <div className="subscription-card">
          <h2>Invalid or Expired Token</h2>
          <p>
            The token provided is either invalid or has expired. Please check
            your subscription link and try again.
          </p>
        </div>
      ) : (
        <div className="subscription-card">
          <h1>Thank you for your subscription</h1>
          <p className="verification-message">
            Please verify the following information is correct before submitting
            your subscription for activation by Hypernovalabs S.A.
          </p>

          <table className="subscription-details">
            <tbody>
              <tr>
                <td>Current user</td>
                <td>{userData.currentUser}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{userData.email || "Unknown"}</td>
              </tr>
              <tr>
                <td>Subscription name</td>
                <td>{userData.subscriptionName || "Unknown"}</td>
              </tr>
              <tr>
                <td>Plan name</td>
                <td>{userData.planName}</td>
              </tr>
              <tr>
                <td>Subscription State</td>
                <td>{userData.subscriptionState || "Unknown"}</td>
              </tr>
              <tr>
                <td>Tenant ID</td>
                <td>{userData.tenantId || "Unknown"}</td>
              </tr>
            </tbody>
          </table>

          <h2>Available Customer Actions</h2>
          <ul className="customer-actions">
            {userData.subscriptionState &&
              (userData.subscriptionState === "PendingFulfillmentStart" ? (
                <li>
                  <Link to={`/notify-activated?id=${userData.id}`}>
                    Notify publisher to activate account
                  </Link>
                </li>
              ) : userData.subscriptionState === "Subscribed" ? (
                <li>
                  <Link to={`/unsubscribe?id=${userData.id}`}>Unsubscribe</Link>
                </li>
              ) : null)}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
