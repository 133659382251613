import React, { useEffect, useState } from "react";
import "./Unsubscribe.css"
import { cancelSubscription } from "../../utils/subscriptionUtils";

const Unsubscribe = () => {
  const [loading, setLoading] = useState(false);

  const ActioncancelSubscription = async ()=>{
    try {
      setLoading(true)
      await cancelSubscription();
    } catch (error) {
      console.error("Error:", error);
    }
    finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    ActioncancelSubscription()  
  }, []);

  return (
    <div className="unsubscribe-container">
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="unsubscribe-card">
      <h1 className="unsubscribe-title">We're sorry to see you go!</h1>
      <p className="unsubscribe-text">
        You have successfully unsubscribed from our service. We hope to have the opportunity to serve you again in the future.
        If you have any questions or need further assistance, please contact our support team.
      </p>
    </div>
      )}
    </div>
  );
  
};

export default Unsubscribe;
