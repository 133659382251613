import React, { useEffect, useState } from "react";
import './NotifyActivated.css'
import { activateSubscription } from "../../utils/subscriptionUtils";

const NotifyActivated = ()  => {

  const [loading, setLoading] = useState(false);

  const ActioncancelSubscription = async ()=>{
    try {
      setLoading(true)
      await activateSubscription();
    } catch (error) {
      console.error("Error:", error);
    }
    finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    ActioncancelSubscription()  
  }, []);

  return (
    <div className="thank-you-container">
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : (
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank you!</h1>
        <p className="thank-you-text">
          Thank you for submitting your subscription for activation. It will take 1-3 days for us to activate your account.
        </p>
        <p className="thank-you-text">
          Please watch for an email from us with further details on next steps.
        </p>
      </div>
      )}
    </div>
  )
}

export default NotifyActivated