// subscriptionUtils.js

export const executeSubscriptionAction = async (endpoint) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const subsId = urlParams.get("id");
  
      const codeParam = `${process.env.REACT_APP_VR_WEB_HOOK_TOKEN}`;
      const fullUrl = `${endpoint}?code=${codeParam}`;
  
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          "subscription-id": subsId,
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error("Error: " + response.statusText);
      }
  
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  export const activateSubscription = async() => {
    const activateEndpoint = `${process.env.REACT_APP_VR_WEB_HOOK_URL}/activatesubscription`;
    await executeSubscriptionAction(activateEndpoint);
  };
  
  export const cancelSubscription = async () => {
    const cancelEndpoint = `${process.env.REACT_APP_VR_WEB_HOOK_URL}/cancelsubscription`;
    await executeSubscriptionAction(cancelEndpoint);
  };
  